<template>
  <page-content>
    <!-- <v-toolbar class="mb-5" flat>
      <v-toolbar-title></v-toolbar-title>
      <v-tabs v-model="selectedTab" center-active>
        <v-tab v-for="tab of tabs" :key="tab.id" :to="'#' + tab.to">{{
          tab.name
        }}</v-tab>
      </v-tabs>
    </v-toolbar> -->
        <!-- <v-card flat outlined> -->
          <v-card-title>
            <v-row>
              <user-info :user="user"></user-info>
              <icon-btn
                outlined
                tooltip="Edytuj"
                @click="edit"
                icon="mdi-pencil-outline"
                v-if="canEdit"
              >
              </icon-btn>
              <icon-btn class="ml-5" outlined
                icon="mdi-key-plus"
                tooltip="Dodaj dostęp"
                @click="addAccess"
                v-if="canEdit"
              ></icon-btn>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-title class="mt-5">
            <v-row>
              Dostęp
              <v-spacer></v-spacer>

            </v-row>
          </v-card-title>
          <user-access-card :user="user" ref="membersView"></user-access-card>
          <!-- <template #subtitle>{{ user }} </template> -->
          <!-- <v-card-title>Informacje</v-card-title> -->
          <!-- <v-card-subtitle>{{ site.info }}</v-card-subtitle> -->
          <!-- <v-card-title>Wskazówka dla patrolu</v-card-title> -->
          <!-- <v-card-subtitle>{{ site.patrol_hint }}</v-card-subtitle> -->
          <!-- <v-card-title>Hasło</v-card-title> -->
          <!-- <v-card-subtitle>{{ site.password }}</v-card-subtitle> -->
          <!-- <v-card-title>Kontrahent</v-card-title> -->
          <!-- <v-card-subtitle
            >{{ site.contractor_id }} / {{ site.guid }}</v-card-subtitle
          > -->
          <template #actions>
            <v-icon class="mr-2" small v-text="info.actionIcon" />
            <div class="text-truncate">
              {{ info.actionText }}
            </div>
          </template>
        <!-- </v-card> -->
      <!-- <v-tab-item value="members" :transition="false">
        <invite-site-member-card
          forUser
          :user="user"
          @submit="invite"
          @new-user="newUser"
        ></invite-site-member-card>
        <user-access-card :user="user" ref="membersView"></user-access-card>
      </v-tab-item> -->
  </page-content>
</template>

<script>
import UserService from "@/services/user.service";
import SiteService from "@/services/site.service";
import { parseJSON } from "date-fns";
import { sync } from "vuex-pathify";

import inviteSiteMemeberCard from "@/components/InviteSiteMemeberCard.vue"

export default {
  name: "User",
  data() {
    return {
      selectedTab: 0,
      tabs: [
        { id: 0, name: "Informacje", to: "info" },
        { id: 1, name: "Dostęp", to: "members" },
      ],
      user: {},
      info: {
        actionIcon: null,
        actionText: null,
        color: "primary",
        icon: "mdi-home",
        header: "K101",
        title: "",
        subtitle: "",
      },
    };
  },
  components: {},
  watch: {},
  computed: {
    canEdit() {
      return this.loggedUser?.permissions?.user.change ?? false;
    },
    loggedUser: sync("user.profile"),
  },
  methods: {
    invite(site, accessLevel) {
      console.log("invite member", this.user);
      SiteService.createMember(site ? site.id : "-", {
        access_level: accessLevel,
        user_id: this.user.id,
      }).then(
        (member) => {
          member.site = site;
          this.$refs.membersView.add(member);
        },
        (err) => {
          console.error("Add members: ", err.response.data);
        }
      );
    },
    newUser() {
      console.log("new user - route");
      this.$router.push({
        name: "Site New User",
        params: { user: {}, site: this.site },
      });
    },
    mediumData(value) {
      return parseJSON(value).toLocaleString(window.navigator.language, {
        dateStyle: "medium",
      });
    },
    edit() {
      this.$router.push({ name: "Edit User", params: { user: this.user } });
    },
    async addAccess() {
      const instance = this
      const dialogInstance = await this.$dialog.show(inviteSiteMemeberCard, {forUser: true})
      console.log("send patrol", dialogInstance)
      dialogInstance.waitForResult = true
      dialogInstance.wait().then((result) => {
        console.log('result :>> ', result);
        if (result === null) {
          return
        }
        instance.invite(result.site, result.accessLevel)
      })
    },
  },
  beforeMount() {
    const userId = this.$route.params.userId;
    UserService.get(userId)
      .then((response) => {
        this.user = response.data;
        console.log("user :>> ", this.user);
      })

    SiteService.getNotes(userId).then((response) => {
      console.log("Response", response.data);
      this.notes = response.data;
    });
  },
};
</script>
